<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Employee Assignments</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small @click="goBack" text>
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              readonly
              label="First Name"
              v-model="dataItem.firstName"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              readonly
              label="Last Name"
              v-model="dataItem.lastName"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              readonly
              label="Middle Name"
              v-model="dataItem.middleName"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              readonly
              label="Employee Code"
              v-model="dataItem.empID"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-tabs background-color="accent" color="white">
            <v-tab :to="`/employees/assignments/${empID}`">Warehouses</v-tab>
            <v-tab :to="`/employees/business-partners/${empID}`"
              >Business Partners</v-tab
            >
            <v-tab :to="`/employees/items/${empID}`">Items</v-tab>
            <v-tab :to="`/employees/payments/${empID}`">Payments</v-tab>
            <v-tab :to="`/employees/branches/${empID}`">Branches</v-tab>
          </v-tabs>
        </v-row>

        <v-row>
          <router-view />
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    empID: null,
    dataItem: {},
  }),
  watch: {
    "$route.params.empID": {
      handler: "getempID",
      immediate: true,
    },
  },
  methods: {
    getempID(id) {
      this.empID = this.$route.params.empID;
      const self = this;
      this.$store
        .dispatch("get", `/employee-master-data/${id}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
